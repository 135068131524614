import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ActionType } from '@shared/types/generic.types';
import { IconComponent, IconName } from '../icon/icon.component';

const IconMap: Record<ActionType, IconName> = {
  primary: 'alert_circle',
  positive: 'check_circle',
  negative: 'alert_circle',
  warning: 'alert_triangle',
  info: 'alert_circle',
};
@Component({
  selector: 'coach-toast',
  imports: [IconComponent],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  type = input<ActionType>();
  title = input<string>();
  description = input<string>();
  icon = computed(() => IconMap[this.type() ?? 'info']);
}
